/*----------------------------------------------------------------------------*/
/*#MESSAGES STYLE*/
/*----------------------------------------------------------------------------*/

.mat-snack-bar-container.mat-snack-bar-container {
  padding: 5px 16px;
  min-height: 20px;

  &.mat-snack-bar-top {
    max-width: inherit;
    width: inherit;
    position: fixed;
    left:  10px;
    top:   55px;
    right: 10px;
  }

  .snackbar__data {
    display: block;
    position: relative;
    padding: 5px 40px;
    font-size: 16px;
    line-height: 1;
    color: #fff;
    word-break: break-word;
    white-space: pre-wrap;

    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top:  50%;
      transform: translateY(-50%);
      font-family: 'Font Awesome 5 Pro', sans-serif;
      font-weight: 700;
      font-size: 25px;
      color: #fff;
    }
  }

  &.success {
    background-color: mat-color($gmb-app-accent, default);
    color: mat-color($gmb-app-accent, text);

    .snackbar__data::before { content: "\f058"; }
  }

  &.error {
    background-color: mat-color($gmb-app-warn, default);
    color: mat-color($gmb-app-warn, text);

    .snackbar__data::before {
      font-weight: 400;
      content: "\f057";
    }
  }

  &.warning {
    background-color: #ffc107!important;
    color: mat-color($gmb-app-warn, text);

    .snackbar__data::before {
      font-weight: 400;
      content: "\f12a";
    }
  }

  &.info {
    background-color: mat-color($gmb-app-primary, default);
    color: mat-color($gmb-app-primary, text);

    .snackbar__data::before {
      font-weight: 400;
      content: "\f05a";
    }
  }
}

.mat-error {
  font-size: 12px;

  &--absolute {
    position: absolute;
    bottom: -17px;
  }

  p {
    font-size: 12px;
    margin-bottom: 0;
  }
}

.notice {
  position: relative;
  width: 100%;
  border-radius: 5px;
  background-color: mat-color($gmb-app-primary, default);
  padding: 10px 10px 10px 35px;

  &--red { background-color: mat-color($gmb-app-warn, default); }

  &::before {
    content: "\f05a";
    font-family: "Font Awesome 5 Pro", sans-serif;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
    font-weight: 700;
    color: rgba(255,255,255,.7);
  }

  p {
    font-size: 12px;
    margin-bottom: 0;
    color: #fff;
  }

  &--lg {
    padding: 15px 15px 15px 45px;
    &::before {
      font-size: 22px;
    }
    p {
      font-size: 18px;
    }
  }
}

.fixed-message {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  z-index: 10;
}

.trial-message {
  @extend .fixed-message;
  background-color: #d20a1c;
  color: #fff;
  z-index: 9999;
  height: 38px;
}

.grade-message {
  @extend .fixed-message;
  background-color: mat-color($gmb-app-accent, 500);
  color: #fff;
  p {
    font-size: 18px;
    line-height: 1;
  }

  ~ .header {
    position: fixed;
    top: 38px;

    ~ [class*='container'] { padding-top: 132px; }
  }
}
