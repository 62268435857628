/*----------------------------------------------------------------------------*/
/*#HEADER STYLE*/
/*----------------------------------------------------------------------------*/

.mat-sidenav-fixed {
  top: 63px!important;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  border-bottom: 1px solid $lightgray;

  &.header--fixed {
    padding-left: 275px!important;
  }

  &__aside {
    max-width: 275px;
    width: 240px;
    height: 100%;
    padding: 0 15px;
    background-color: mat-color(mat-palette($gmb-darkblue, 500));

    .mlogo {
      max-width: 170px;
      width: 100%;
      max-height: 60px;

      img {
        max-height: 50px;
        width: inherit;
      }

      .mat-button { width: 100%; }
    }

    &.header--fixed {
      position: fixed;
      top: 0;
      left: 0;
      height: 64px;
      background-color: mat-color(mat-palette($gmb-darkblue, 500));
      z-index: 9;
    }
  }

  &__content {
    flex: 1 0 auto;

    form { flex-grow: 1; }
  }
}

.mat-menu-header.mat-menu-panel { width: 200px; }

.mat-sidenav .mat-drawer-inner-container { 
  height: calc(100vh - 63px);
  background-color: #F4F7FC;
}

.mat-drawer-inner-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar for WebKit browsers */
}

.menu {
  &__item {
    display: inline-block;
    margin-bottom: 0;
    color: #737373;
    transition: .3s ease-in-out;

    .mat-button {
      position: relative;
      height: $header-height;
      border-left: 1px solid #EFF0F3;
      padding: 10px 25px;

      &-wrapper {
        display: flex;
        align-items: center;
      }

      &.active {
        position: relative;

        &::before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: mat-color($gmb-app-primary);
          animation: height .2s .2s linear forwards;
        }

        color: mat-color($gmb-app-primary);
        svg path { fill: mat-color($gmb-app-primary); }
      }
    }

    &__icon {
      display: inline-block;
      max-width: 20px;
      width: 100%;
      height: 20px;
      margin-right: 15px;
      font-size: 0;

      &.right {
        margin-left: 15px;
      }

      &.blue {
        color: #328dd4 !important;
      }

      &.disabled {
        color: rgba(95, 95, 101, 0.4);
        svg path {
          fill: rgba(95, 95, 101, 0.4) !important;
          stroke: rgba(95, 95, 101, 0.4) !important;
        }
      }

      svg {
        max-width: 20px;
        height: 20px;
      }
    }

    &__img {
      border-radius: 50px;
      width:  45px;
      height: 45px;
      overflow: hidden;

      img {
        display: block;
        object-fit: cover;
        max-width: 100%;
      }
    }

    &__disabled {
      color: rgba(95, 95, 101, 0.4);
    }

    &--profile {
      max-width: 200px;
      width: 100%;

      .mat-button { width: 200px; }

      .profile { width: 100%; }
    }

    svg path {
      transition: .3s ease-in-out;
      fill: #737373;
      stroke: none;
    }

    &:hover {
      color: mat-color($gmb-app-primary);
      svg path { fill: mat-color($gmb-app-primary); }
    }

    &--notification {
      .mat-badge-after .mat-badge-content {
        top:  -10px;
        right: -7px;
      }
    }
  }
}

@keyframes height {
  0%   { height: 0; }
  100% { height: 5px; }
}

.profile {
  &__img img {
    display: block;
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50px;
  }

  &__txt {
    white-space: pre-wrap;
    padding: 0 3px 0 10px;
    > * { line-height: 1; }
  }
}


.notification {

  &__header {
    padding: 15px 10px 10px;

    .mat-badge-content {
      position: relative;
      top: 0!important;
    }
  }
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: nowrap;
    position: relative;
    color: #737373;
    padding: 10px;
    border-radius: 4px;
    background: transparent;
    transition: .3s ease-in-out;
    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left:   0;
      right:  0;
      width: 100%;
      height: 1px;
      background: #e2e4ea;
    }

    &.no-hover {
      padding: 10px 12px;
      cursor: default;
      &::after {
        bottom: inherit;
        top: 0;
      }

      &.no-border::after { display: none; }
    }

    &:not(.no-hover):hover {
      //background-color: #e2e4ea;
      background-color: rgba(239, 240, 243, .5);
      .notification__title { color: #000; }
    }
  }

  &__title {
    margin-bottom: 0;
    flex-grow: 1;
    transition: .3s ease-in-out;
  }
  &__time {
    display: block;
    width: 100px;
    font-size: 12px;
    line-height: 1;
    opacity: .7;
    margin-top: 5px;
  }
}


.mat-chip-list.notification-chip {
  .mat-standard-chip {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    line-height: 1;
    min-height: 22px;
    min-width:  22px;
    padding: 5px;
  }

  &--sm {
    .mat-standard-chip { font-size: 10px; }
  }

  &--absolute {
    position: absolute;
    top:   10px;
    right: 20px;
  }
}

.mat-menu-panel {

  .mat-menu-content:not(:empty) { padding: 10px; }

  &.mat-elevation-z2 { box-shadow: 0 10px 20px 0 rgba(63,70,74,.25); }

  .mat-button {
    line-height: 1;
    &:not(.notification) {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 12px 10px;
      transition: .3s ease-in-out;

      .mat-button-wrapper {
        color: #737373;
        transition: .3s ease-in-out;
      }
      &.mat-primary:hover .mat-button-wrapper { color: mat-color($gmb-app-primary, default); }
      &.mat-warn:hover .mat-button-wrapper { color: mat-color(mat-palette($gmb-orange, 500)); }
    }
  }

  &.notification-menu {
    width: 400px;
    max-width: 100%;
    max-height: 500px;
  }
}

.register-logo {
  display: block;

  img { max-width: 200px; }

  @media (max-width: $screen-xl) {
    img { max-width: 150px; }
  }
}


