/*----------------------------------------------------------------------------*/
/*#SIDEBAR STYLE*/
/*----------------------------------------------------------------------------*/

.sidebar {
  width: 275px;
  background-color: mat-color(mat-palette($gmb-darkblue, 400));

  &__title {
    position: relative;
    display: block;
    color: #5F5F65;
    padding: 20px 15px;
    font-size: 14px;
    background-color: transparent;
    transition: all .3s ease-in-out;
    min-height: 85px;

    h2 {
      font-size: 20px;
      line-height: 1em;
    }

  }

  &__item {
    position: relative;
    display: block;
    color: #0060CD;
    padding: 20px 15px;
    font-size: 14px;
    background-color: transparent;
    transition: all .3s ease-in-out;
    @media (max-height: 778px) {
      padding-top:    11px;
      padding-bottom: 11px;
    }

    i {
      display: inline-block;
      width: 40px;
    }

    &.active,
    &:hover {
      color: #2F2F33
    }
  }

  &__group {
    &:not(:last-child) {
      padding: 10px 0;
      // border-bottom: 1px solid #E8E8E9;
    }

    &:first-child {
      padding-top: 30px;
    }
  }

  .group-name { 
    color: #5F5F65;
    font-size: 16px;
    font-weight: 500;
    margin: 10px 0 0 15px;
  }
}

// mat-drawer backdrop
.mat-drawer-backdrop.mat-drawer-shown { 
  background-color: rgba(0,0,0,0.75) !important;
}
.mat-drawer.mat-sidenav { border-right: none; }

