/*----------------------------------------------------------------------------*/
/*#VARIABLES*/
/*----------------------------------------------------------------------------*/
//sprites
.register-1, .register-2, .register-3, .register-4, .register-5, .register-6, .register-7, .register-8
{ display: inline-block; overflow: hidden; width: 37px; height: 37px;  }

.register-1 { background: url('assets/images/register/chart-bar-solid.svg') no-repeat; }
.register-2 { background: url('assets/images/register/check-double-solid.svg') no-repeat; }
.register-3 { background: url('assets/images/register/clock-solid.svg') no-repeat; }
.register-4 { background: url('assets/images/register/code-solid.svg') no-repeat; }
.register-5 { background: url('assets/images/register/newspaper-solid.svg') no-repeat; }
.register-6 { background: url('assets/images/register/redo-solid.svg') no-repeat; }
.register-7 { background: url('assets/images/register/star-solid-1.svg') no-repeat; }
.register-8 { background: url('assets/images/register/tachometer-alt-solid.svg') no-repeat; }

.bg--login-first-row1 {
    // background: url('assets/images/bg-logo-blue.svg') no-repeat; // fix this line
}

//colors
$header-height: 64px;

//SCREEN SIZES
$screen-xl: 1500px;
$screen-lg: 1199px;
$screen-md: 991px;
$screen-sm: 767px;
$screen-xs: 585px;
$screen-xxs: 400px;

//colors
$lightgray:  #eeeff3;
$table-border: #e7e9ed;
$thead: #f7f8fe;

//theme colors
$gmb-blue: (
    50 : #e5f3fe,
    100 : #bfe2fc,
    200 : #94cffb,
    300 : #69bbf9,
    400 : #49adf7,
    500 : #299ef6,
    600 : #2496f5,
    700 : #1f8cf3,
    800 : #1982f2,
    900 : #0f70ef,
    A100 : #ffffff,
    A200 : #e8f1ff,
    A400 : #b5d2ff,
    A700 : #9cc3ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$gmb-green: (
    50 : #e4f9e6,
    100 : #c4e9c9,
    200 : #9cdaa5,
    300 : #74cb80,
    400 : #57c065,
    500 : #39b54a,
    600 : #33ae43,
    700 : #2ca53a,
    800 : #249d32,
    900 : #178d22,
    A100 : #c3ffc8,
    A200 : #90ff99,
    A400 : #5dff6a,
    A700 : #44ff52,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$gmb-red: (
    50 : #ffe9e9,
    100 : #ffc9c9,
    200 : #ffa5a5,
    300 : #ff8080,
    400 : #ff6565,
    500 : #ff4a4a,
    600 : #ff4343,
    700 : #ff3a3a,
    800 : #ff3232,
    900 : #ff2222,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffd1d1,
    A700 : #ffb8b8,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

//alternate theme

$gmb-orange: (
    50 : #fdeec8,
    100 : #fbe3bf,
    200 : #f9d195,
    300 : #f6bf6b,
    400 : #f4b14b,
    500 : #f2a32b,
    600 : #f09b26,
    700 : #ee9120,
    800 : #ec881a,
    900 : #e87710,
    A100 : #ffffff,
    A200 : #ffefe2,
    A400 : #ffd2af,
    A700 : #ffc496,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$gmb-yellow: (
    50 : #FFFFB7,
    100 : #FFF8A5,
    200 : #FFF599,
    300 : #FFF185,
    400 : #FFEE70,
    500 : #FFEC5C,
    600 : #FFE747,
    700 : #FFE433,
    800 : #FFDD1F,
    900 : #FFDA0A,
    A100 : #ffffff,
    A200 : #FFDA0A,
    A400 : #FFDA0A,
    A700 : #FFDA0A,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$gmb-gray: (
    50 : #fdfdfe,
    100 : #fafbfb,
    200 : #f7f8f9,
    300 : #f4f5f7,
    400 : #f1f2f5,
    500 : #eff0f3,
    600 : #edeef1,
    700 : #ebecef,
    800 : #e8e9ed,
    900 : #d5d5d6,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #8c91a2,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$gmb-darkblue: (
  50 : #e4e5e7,
  100 : #babdc2,
  200 : #8d9199,
  300 : #5f6570,
  400 : #242e40,
  500 : #1a2333,
  600 : #171f2e,
  700 : #131a27,
  800 : #0f1520,
  900 : #080c14,
  A100 : #5781ff,
  A200 : #245bff,
  A400 : #003cf0,
  A700 : #0036d6,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);


$gmb-darkgray: (
    50 : #fdfdfe,
    100 : #fafafb,
    200 : #f7f7f9,
    300 : #f3f4f7,
    400 : #f1f1f5,
    500 : #eeeff3,
    600 : #ecedf1,
    700 : #e9ebef,
    800 : #e7e8ed,
    900 : #e2e4ea,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);


